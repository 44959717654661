import { Injectable } from '@angular/core';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersProxyClient } from 'src/proxy/apiUsers/users.proxy';
import { mapUserDtoToUser } from './mappings/back-office.mappings';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private usersClient: UsersProxyClient, private translate: TranslateService) { }

  public getUserData(): Observable<RiskallayUser> {
    return this.usersClient
      .usersGetUserDataGet()
      .pipe(
        map(u => mapUserDtoToUser(u, this.translate.currentLang))
      );
  }


  public getAllUsers(): Observable<RiskallayUser[]> {
    return this.usersClient
      .usersGetAllUserDataGet()
      .pipe(
        map(users => users.map(u => mapUserDtoToUser(u, this.translate.currentLang)))
      );
  }
}
