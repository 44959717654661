import { RemoteService } from './remote.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http: HttpClient,
    @Inject('API_URL') public serverUrl: string
  ) { }

  public updateVisibleInfo(data) {
    const formData = this.ConstruirFormDataVisibleInfo(data);
    return this.http.put(this.serverUrl + `Users/UpdateVisibleInfoClientData`, formData);
  }

  private ConstruirFormDataVisibleInfo(data): FormData {
    const formData = new FormData();

    formData.append('Name', data.name);

    if (data.selectedCoverFileUri !== null && data.selectedCoverFileUri !== undefined)
      formData.append('CoverPageImageFileUri', data.selectedCoverFileUri);

    if (data.selectedCoverFileName !== null && data.selectedCoverFileName !== undefined)
      formData.append('CoverPageImageFileName', data.selectedCoverFileName);

    if (data.selectedCoverFile) {
      formData.append('CoverPageImageFile', data.selectedCoverFile);
    }

    if (data.selectedIconFileUri !== null && data.selectedIconFileUri !== undefined)
      formData.append('IconImageFileUri', data.selectedIconFileUri);

    if (data.selectedIconFileName !== null && data.selectedIconFileName !== undefined)
      formData.append('IconImageFileName', data.selectedIconFileName);

    if (data.selectedIconFile) {
      formData.append('IconImageFile', data.selectedIconFile);
    }

    return formData;
  }


  updateAccountInfo(data) {
    const formData = this.ConstruirFormDataAccountInfo(data);
    return this.http.put(this.serverUrl + `Users/UpdateUserData`, formData);
  }

  async getFileStorageKey(filename: string): Promise<any>{
    const res = await this.http.get(`${this.serverUrl}Users/UpdateUserImage/${filename}`).toPromise();

    return res;
  }

  private ConstruirFormDataAccountInfo(data): FormData {
    const formData = new FormData();

    formData.append('Id', data.id);
    formData.append('Name', data.name);
    formData.append('SurName', data.surName);
    formData.append('Email', data.email);
    formData.append('Position', data.position);
    if (data.oldPassword !== null && data.oldPassword !== undefined)
    formData.append('OldPassword', data.oldPassword);

    if (data.newPassword !== null && data.newPassword !== undefined)
    formData.append('NewPassword', data.newPassword);

    if (data.profilePhotoFileName !== null && data.profilePhotoFileName !== undefined)
      formData.append('ProfilePhotoFileName', data.profilePhotoFileName);

    if (data.profilePhotoFileUri !== null && data.profilePhotoFileUri !== undefined)
    formData.append('ProfilePhotoFileUri', data.profilePhotoFileUri);

    if (data.profilePhotoFile) {
      formData.append('ProfilePhotoFile', data.profilePhotoFile);
    }

    formData.append('LanguageId', data.languageId);
    formData.append('TwoFactorEnabled', data.twoFactorEnabled);

    return formData;
  }

  async getFileStorageKeyClientPublicImage(filename: string): Promise<any>{
    const res = await this.http.get(`${this.serverUrl}Users/SaveClientPublicImage/${filename}`).toPromise();
    return res;
  }


}
