import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { AuthenticationService } from './services/authentication.service';
import { AppRoutingCache } from './app-routing-cache';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { MainComponent } from '@components/main/main.component';
import { LoginComponent } from '@app/modules/access/login/login.component';
import { CustomToastComponent } from '@components/custom-toast/custom-toast.component';
import { ForgottenPasswordComponent } from '@app/modules/access/forgotten-password/forgotten-password.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CustomMatPaginatorIntl } from './utilities/custom-paginator';
import { DatePipe } from '@angular/common';
import { SecurityInterceptorService } from './security/security-interceptor.service';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { MyAccountComponent } from '@components/my-account/my-account.component';
import { ResetPasswordComponent } from './modules/access/reset-password/reset-password.component';
import { BackofficeLoginComponent } from './modules/backoffice/backoffice-login/backoffice-login.component';
import { BackofficeAccountsComponent } from './modules/backoffice/backoffice-accounts/backoffice-accounts.component';
import { NewAccountDialogComponent } from './modules/backoffice/new-account-dialog/new-account-dialog.component.';
import { BackofficeAccountsTableComponent } from './modules/backoffice/backoffice-accounts-table/backoffice-accounts-table.component';
import { AppTwoStepVerificationComponent } from './modules/access/app-two-step-verification/app-two-step-verification.component';
import { MatDividerModule } from '@angular/material/divider';
import { appInitializer } from './security/appInitializer';
import { ErrorInterceptor } from './security/error.interceptor';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { AccountFormComponent } from './modules/backoffice/account-form/account-form.component';
import { setLanguageFromBrowser } from './utilities/locale-id.provider';
import { TranslateDescriptionPipe } from './shared/pipes/translate-description.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DialogConfirmExitComponent } from './components/dialog-confirm-exit/dialog-confirm-exit.component';
import { BackOfficeService } from './services/back-office.service';
import { CountriesService } from './services/countries.service';
import { UsersService } from './services/users.service';
import { UsersProxyClient } from 'src/proxy/apiUsers/users.proxy';
import { BackOfficeProxyClient } from 'src/proxy/apiBackOffice/backOffice.proxy';
import { UsageProxyClient } from 'src/proxy/apiUsage/usage.proxy';
import { UsageService } from './services/usage.service';
import { ViewAccountComponent } from './modules/backoffice/view-account/view-account.component';
import { ConsumptionUsageComponent } from './modules/backoffice/consumption-usage/consumption-usage.component';
import { ThirdPartyUsageComponent } from './modules/backoffice/third-party-usage/third-party-usage.component';
import { AssessmentUsageComponent } from './modules/backoffice/assessment-usage/assessment-usage.component';
import { DialogUpdatePasswordComponent } from './components/my-account/dialog-update-password/dialog-update-password.component';
import { AccountService } from './services/account.service';
import { AccountProxyClient } from 'src/proxy/apiAccount/account.proxy';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    CustomToastComponent,
    ForgottenPasswordComponent,
    FileUploadComponent,
    MyAccountComponent,
    ResetPasswordComponent,
    BackofficeLoginComponent,
    BackofficeAccountsComponent,
    NewAccountDialogComponent,
    BackofficeAccountsTableComponent,
    DialogConfirmExitComponent,
    AppTwoStepVerificationComponent,
    AccountFormComponent,
    TranslateDescriptionPipe,
    ViewAccountComponent,
    ConsumptionUsageComponent,
    ThirdPartyUsageComponent,
    AssessmentUsageComponent,
    DialogUpdatePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatChipsModule,
    MatDividerModule,
    ToastNotificationsModule.forRoot({ component: CustomToastComponent }),
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatProgressBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatButtonToggleModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    { provide: 'API_URL', useValue: environment.hostUrl },
    { provide: RouteReuseStrategy, useClass: AppRoutingCache },
    {
      provide: LOCALE_ID,
      useFactory: setLanguageFromBrowser,
      deps: [TranslateService],
    },
    MatDatepickerModule,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    DatePipe,
    TranslateDescriptionPipe,
    BackOfficeService,
    BackOfficeProxyClient,
    CountriesService,
    CommonsProxyClient,
    UsersService,
    UsersProxyClient,
    UsageProxyClient,
    UsageService,
    AccountService,
    AccountProxyClient
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
