import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';

//login
import { LoginComponent } from '@app/modules/access/login/login.component';
import { ForgottenPasswordComponent } from '@app/modules/access/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from '@app/modules/access/reset-password/reset-password.component';

import { MainComponent } from './components/main/main.component';
//Mi cuenta
import {MyAccountComponent} from '@components/my-account/my-account.component';

//Backoffice
import { BackofficeLoginComponent } from '@modules/backoffice/backoffice-login/backoffice-login.component';
import { BackofficeAccountsComponent } from '@modules/backoffice/backoffice-accounts/backoffice-accounts.component';
import { EsBackOfficeGuard } from './security/es-backoffice.guard';
import { AppTwoStepVerificationComponent } from './modules/access/app-two-step-verification/app-two-step-verification.component';
import { ViewAccountComponent } from './modules/backoffice/view-account/view-account.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  
  //Basicas
  { path: 'login', component: LoginComponent },
  { path: 'twostepverification', component: AppTwoStepVerificationComponent },
  { path: 'he-olvidado-contrasena', component: ForgottenPasswordComponent},
  { path: 'cambio-de-contrasena', component: ResetPasswordComponent },
  { path: 'main', component: MainComponent, canActivate: [AuthGuardService] },
  { path: 'mi-perfil', component: MyAccountComponent, canActivate: [AuthGuardService] },

  //Backoffice
  { path: 'backoffice-login', component: BackofficeLoginComponent },
  { path: 'backoffice-listado-cuentas', component: BackofficeAccountsComponent, canActivate: [AuthGuardService, EsBackOfficeGuard] },
  { path: 'client-details/:clientId', component: ViewAccountComponent, canActivate: [AuthGuardService, EsBackOfficeGuard, ]},

  // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
