<div class="o-wrapper">
  <main id="content" *ngIf="model">
    <!--encabezado de sección-->
    <div class="c-head-section">
      <div class="c-head-section__left">
        <h2 class="o-title">{{ model.client.name }}</h2>
        <div class="c-go-back">
          <a [routerLink]="['/backoffice-listado-cuentas']" class="c-go-back__button">
              <span class="c-go-back__arrow" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                      <path d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0" transform="translate(-5-6.01)"/>
                  </svg>
              </span>
              {{'TwoStepVerification.back' | translate}}
          </a>
      </div>
      </div>
    </div>
    <!--contenido: buscador y tabla-->
    <div class="c-table u-mt-1medium">
      <app-consumption-usage [client]="model.client"></app-consumption-usage>
      <div class="c-dialog__content small-padding u-pb-0">
        <app-account-form
            [model]="model"
            (formSubmitted)="updateAccount($event)"
            (validationChanged)="checkFormValid($event)">
        </app-account-form>
      </div>
      <footer class="c-dialog__footer">
        <button [disabled]="!isFormValid" (click)="submitForm()" class="c-button c-button--green u-align-right"
          mat-button>{{'TablaBackofficeUsersForm.updateButtonLabel.valor' | translate}}</button>
      </footer>
    </div>
  </main>
</div>
