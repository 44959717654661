import { Client } from "@app/models/Client";
import { ClientDto } from "src/proxy/model/clientDto";
import { mapCountry } from "./country.mappings";

export function mapClientDtoToClient(dto: ClientDto, currentLanguage: string): Client {
    if (dto === null) {
        return null;
    }

    return <Client>{
        companyCif: dto.CompanyCif,
        companyRegistrationFileName: dto.CompanyRegistrationFileName,
        companyRegistrationFileUri: dto.CompanyRegistrationFileUri,
        countryId: dto.Country?.Id,
        country: mapCountry(dto.Country, currentLanguage),
        coverPageImageFileName: dto.CoverPageImageFileName,
        coverPageImageFileUri: dto.CoverPageImageFileUri,
        iconImageFileName: dto.IconImageFileName,
        iconImageFileUri: dto.IconImageFileUri,
        id: dto.Id,
        name: dto.Name,
        numOfLicenses: dto.NumOfLicenses,
        otherTypeOfActivity: dto.OtherTypeOfActivity,
        typeOfActivity: dto.TypeOfActivity,
        maxThirdParties: dto.MaxThirdParties,
        refinitivQuota: dto.RefinitivQuota,
        amlUpdateQuota: dto.AMLUpdateQuota,
        equifaxWSQuota: dto.EquifaxWSQuota,
        active: dto.Active,
        trial: dto.Trial,
        trialEnd: dto.TrialEnd ? new Date(dto.TrialEnd) : null,
        contractStartDate: dto.ContractStartDate ? new Date(dto.ContractStartDate) : null,
        memberCheckQuota: dto.MemberCheckQuota,
        APIEnabled: dto.APIEnabled
    };
}
