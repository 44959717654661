import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@app/models/Client';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackOfficeProxyClient } from 'src/proxy/apiBackOffice/backOffice.proxy';
import {
  mapUserToUserCreateDto,
  mapUserDtoToUser,
  mapUserToUserUpdateDto,
} from './mappings/back-office.mappings';
import { mapClientDtoToClient } from './mappings/client.mappings';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeService {
  constructor(private backOfficeClient: BackOfficeProxyClient, private translate: TranslateService) { }

  public createClient(user: RiskallayUser): Observable<HttpResponse<void>> {
    return this.backOfficeClient.backOfficeCreateClientPost(
      mapUserToUserCreateDto(user)
    );
  }

  public allClientData(): Observable<Client[]> {
    return this.backOfficeClient.backOfficeClientsGet()
      .pipe(map((clients) => clients.map(client => mapClientDtoToClient(client, this.translate.currentLang))));
  }

  public clientDataById(accountId: number): Observable<RiskallayUser> {
    return this.backOfficeClient
      .backOfficeGetDataByClientIdIdClientGet(accountId)
      .pipe(map((c) => mapUserDtoToUser(c, this.translate.currentLang)));
  }

  public updateClient(user: RiskallayUser): Observable<RiskallayUser> {
    return this.backOfficeClient
      .backOfficeUpdateClientIdClientPut(user.client?.id, mapUserToUserUpdateDto(user))
      .pipe(map((c) => mapUserDtoToUser(c, this.translate.currentLang)));
  }
}
