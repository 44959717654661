import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from '@app/models/Client';
import { Usage } from '@app/models/Usage';
import { UsageService } from '@app/services/usage.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-consumption-usage',
  templateUrl: './consumption-usage.component.html',
  styleUrls: ['./consumption-usage.component.scss']
})
export class ConsumptionUsageComponent implements OnInit {
  @Input() client: Client;
  licenseUsage: Usage;
  thirdPartyUsage: Usage;
  refinitivUsage: Usage;
  amlUpdateUsage: Usage;
  equifaxWSUsage: Usage;
  memberCheckUsage: Usage;
  assessmentsLaunched: number;
  loading: boolean = true;

  constructor(private usageService: UsageService, private dialog: MatDialog) { }

  ngOnInit(): void {
    forkJoin({
      thirdParty: this.usageService.thirdPartyUsage(this.client.id),
      refinitiv: this.usageService.refinitivApiUsage(this.client.id),
      amlUpdate: this.usageService.amlUpdateApiUsage(this.client.id),
      equifaxWS: this.usageService.equifaxWSApiUsage(this.client.id),
      memberCheck: this.usageService.memberCheckApiUsage(this.client.id),
      assessments: this.usageService.assessmentUsage(this.client.id),
      licenses: this.usageService.licenseUsage(this.client.id),
    })
      .subscribe(({ thirdParty, refinitiv, assessments, licenses, amlUpdate, equifaxWS, memberCheck}) => {
        this.thirdPartyUsage = thirdParty;
        this.refinitivUsage = refinitiv;
        this.assessmentsLaunched = assessments;
        this.licenseUsage = licenses;
        this.amlUpdateUsage = amlUpdate;
        this.equifaxWSUsage = equifaxWS;
        this.memberCheckUsage = memberCheck

        this.loading = false;
      });
  }
}
