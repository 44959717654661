import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, NgModel } from '@angular/forms';
import { Router } from '@angular/router';

import { RemoteService } from '@app/services/remote.service'
import { AuthenticationService } from '@app/services/authentication.service';

import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '@app/models/Role';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  email: string;
  sent = false;
  typePassword: boolean;
  isShow = false;
  form: FormGroup;
  titulo: string;
  submitted = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private remote: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    @Inject('API_URL') public serverUrl: string,
  ) {

    if (this.auth.accountValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
  });

    //Obtenermos altura de ventana y la multiplicamos por 1% para obtener valor para vh
    let vh = window.innerHeight * 0.01;
    //Establecemos valor de --vh a la raíz del documento
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    //Evento redimensionar
    window.addEventListener('resize', () => {
      //Se ejecuta mismo script que antes
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Make sure we are not logged in
    //this.auth.logout()
  }


  sendMail() {
    let emailInfo = { email: this.email }
    this.remote.postRequest('account/sendLogin/', emailInfo)
      .then(r => this.sent = true)
      .catch(r => {
        this.translate.get('Login.log_error_send.valor').subscribe((text: string) => {
          this.toaster.open({ text: text, duration: 3000, type: 'danger' });
        });
      }

      )
  }

  get f() { return this.form.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    
    this.auth.authenticate(this.f.username.value, this.f.password.value)
      .then(r => {
        let loginResult = r
        if (loginResult=== 'User Locked Out') {
          this.loading = false;
          this.translate.get('Login.userLockedOut').subscribe((text: string) => {
            this.toaster.open({ text: text, duration: 10000, type: 'danger' });
          });
          return;
        }

        if (loginResult.Is2StepVerificationRequired) {
          this.router.navigate(['/twostepverification'],
            { queryParams: { provider: loginResult.Provider, email: this.f.username.value } });
        }
        else if (loginResult.IsAuthSuccessful) {
          var rol = loginResult.Role;
          if (rol == Role.BackOffice) {
            this.router.navigateByUrl('/backoffice-listado-cuentas')
          }
          else {
            this.router.navigateByUrl('/home')
          }
        }
        else {
          this.loading = false;
          this.translate.get('Login.log_error.valor').subscribe((text: string) => {
            this.toaster.open({ text: text, duration: 6000, type: 'danger' });
          });

        }
      })
      .catch(r => {
        this.loading = false;
        this.translate.get('Login.log_error_server.valor').subscribe((text: string) => {
          this.toaster.open({ text: text, duration: 6000, type: 'danger' });
        });
      })
  }

  //Mostrar password
  togglePassword = function () {
    this.typePassword = !this.typePassword;
    this.isShow = !this.isShow;
  };

}
