<section class="c-login">
    <div class="c-login__wrapper">
        <!--parte izquierda - imagen-->
        <div class="c-login__left" aria-hidden="true">
            <div class="c-login-header">
                <div class="c-login-header__image" aria-hidden="true">
                    <img src="./assets/image-logo.svg" alt="" loading="lazy" />
                </div>
                <h1 class="c-login__logo" lang="en">
                    <img src="./assets/logo-riskallay-login.svg" alt="Riskallay Thirdparty" loading="lazy" />
                </h1>
            </div>
            <div class="c-login__opacity"></div>
        </div>
        <!--parte derecha - form-->
        <div class="c-login__right">
            <div class="c-login__right-top">
                <!--volver a login-->
                <div class="c-go-back">
                    <a [routerLink]="['/login']" class="c-go-back__button">
                        <span class="c-go-back__arrow" aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                                <path d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0" transform="translate(-5-6.01)"/>
                            </svg>
                        </span>
                        {{'ForgottenPassword.back' | translate}}
                    </a>
                </div>
                <h2 class="c-login__subtitle">{{'ForgottenPassword.subTitle' | translate}}</h2>
                <p class="o-text">{{'ForgottenPassword.text' | translate}}</p>
                <form class="c-login-form" autocomplete="off">
                    <fieldset>
                        <legend class="u-sr-only">{{'ForgottenPassword.formTitle' | translate}}</legend>
                        <!--email-->
                        <div class="c-login-form__block">
                            <mat-form-field>
                                <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"  matInput name="" value="" id="recover-mail"  class="c-login__input"  placeholder="{{'ForgottenPassword.emailPlaceholder' | translate}}" />
                            </mat-form-field>
                        </div>
                        <button type="submit" *ngIf="sent == false" (click)="sendMail()" class="c-button">{{'ForgottenPassword.sendEmail' | translate}}</button>
                        <button role="alert" *ngIf="sent == true" class="c-button c-button--green" disabled="disabled">{{'ForgottenPassword.emailSent' | translate}}</button>
                    </fieldset>
                </form>
            </div>
           <!--por ahora está vacío, en un futuro irá imagen-->
           <div class="c-login__right-bottom"></div>
        </div>
    </div>
</section>
