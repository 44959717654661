import { TranslateService } from '@ngx-translate/core';

export const setLanguageFromBrowser = (translate: TranslateService) => {
  const browserLanguage = navigator.language.split('-')[0];
  switch (browserLanguage) {
    case 'es':
      translate.use('es-ES');
      return 'es-ES';
    case 'en':
      translate.use('en-US');
      return 'en-US';
    case 'pt':
      translate.use('pt-PT');
      return 'pt-PT';
    default:
      translate.use('en-US');
      return 'en-US';
  }
};
