import { Component, OnInit, ViewChild } from '@angular/core';
import { RiskallayUser } from '../../../models/RiskallayUser';
import { BackOfficeService } from '@app/services/back-office.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '@app/models/Client';
import { AccountFormComponent } from '../account-form/account-form.component';

@Component({
  selector: 'app-new-account-dialog',
  templateUrl: './new-account-dialog.component.html',
  styles: [],
})
export class NewAccountDialogComponent implements OnInit {
  @ViewChild(AccountFormComponent) accountFormComponent: AccountFormComponent;
  public isFormValid: boolean;
  model: RiskallayUser = <RiskallayUser>{
    client: <Client>{},
  };
  successMessage: string;
  translationSectionName = '';

  constructor(
    private backOffice: BackOfficeService,
    public dialogRef: MatDialogRef<NewAccountDialogComponent>,
    private toaster: Toaster,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate
      .get('TablaBackofficeUsersForm.createSuccessMessage.valor')
      .subscribe((translation) => {
        this.successMessage = translation;
      });
  }

  addAccount(user: RiskallayUser): void {
    this.backOffice.createClient(user).subscribe(
      () => {
        this.toaster.open({
          text: this.successMessage,
          duration: 6000,
          type: 'info',
        });
        this.dialogRef.close(true);
      },
      () => this.dialogRef.close(false)
    );
  }

  checkFormValid(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  submitForm(): void {
    this.accountFormComponent.submitForm();
  }

  closeForm(): void {
    this.dialogRef.close();
  }
}
