import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {

    constructor(private translate: TranslateService) { }

    chageLanguage(language: string) {
        if (language === '2') {
            this.translate.use('es-ES');
        } else if (language === '3') {
            this.translate.use('en-US');
        } else if (language === '4') {
            this.translate.use('pt-PT');
        } else {
            this.translate.use('es-ES');
        }
    }
}
