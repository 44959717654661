import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import download from 'downloadjs';
import { AwsS3Service } from './awsS3.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    @Inject('API_URL') public serverUrl: string,
    private awsS3Service: AwsS3Service
  ) {}

  // Generic functions
  public async getRequest(url) {
    let token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const headerObj = { headers: headers };

    url = this.serverUrl + url;
    const result = this.http.get<any>(url, headerObj).toPromise();

    // Prevent token expiration
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  public async postRequest(url, data) {
    let token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const headerObj = { headers: headers };

    url = this.serverUrl + url;
    const result = this.http
      .post<any>(url, JSON.stringify(data), headerObj)
      .toPromise();

    // Prevent expired tokens
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  public async putRequest(url, data) {
    let token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const headerObj = { headers: headers };

    url = this.serverUrl + url;
    const result = this.http
      .put<any>(url, JSON.stringify(data), headerObj)
      .toPromise();

    // Prevent expired tokens
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  public async deleteRequest(url, data) {
    let token = this.auth.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const headerObj = { headers: headers };

    url = this.serverUrl + url;
    const result = this.http.delete<any>(url, headerObj).toPromise();

    // Prevent token expiration
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  public async postFilesRequest(file: File, url) {
    let token = this.auth.getToken();

    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      //'Accept': 'application/json',
      Authorization: 'Bearer ' + token,
    });

    const headerObj = { headers: headers };

    url = this.serverUrl + url;
    const result = this.http.post<any>(url, formData, headerObj).toPromise();

    // Prevent expired tokens
    result.catch((e) => {
      if (e.status == 401) {
        this.auth.logout();
      }
    });

    return result;
  }

  downloadMyFile(idFile: string, nameFile: string) {
    this.http
      .get<any>(`${this.serverUrl}Commons/GetTokenFileUri?fileUri=${idFile}`)
      .subscribe((res) => {
        if (res.url) {
          this.awsS3Service.downloadFileFromS3(res.url).subscribe((r) => {
            download(r, nameFile, 'application/octet-stream');
          });
        }
      });
  }

  public getTokenFileUri(fileUri: string): Observable<any> {
    return this.http.get(
      `${this.serverUrl}Commons/GetTokenFileUri?fileUri=${fileUri}`
    );
  }
}
