import { Component, Input, OnInit } from "@angular/core";
import { UsageService } from "@app/services/usage.service";

@Component({
    selector: 'app-third-party-usage',
    templateUrl: './third-party-usage.component.html',
    styles: []
})
export class ThirdPartyUsageComponent implements OnInit {

    @Input() clientId: number;

    usage: string = '-/-';

    constructor(private usageService: UsageService) { }

    ngOnInit(): void {
        this.usageService.thirdPartyUsage(this.clientId).subscribe(r => this.usage = `${r.total - r.remaining}/${r.total}`);
    }    
}
