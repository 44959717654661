import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { RemoteService } from '@app/services/remote.service';
import { GlobalService } from '@app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import { Role } from '@app/models/Role';
import { canAccess } from '@app/security/riskallay.roles';
import { mapPositionToTranslationKey, Position } from '@app/models/Position';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localePt, 'pt');

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  loggedIn = false;
  image: string;
  URLHome = '';

  constructor(
    public auth: AuthenticationService,
    public global: GlobalService,
    private router: Router,
    private remote: RemoteService,
    private translate: TranslateService,
    @Inject(LOCALE_ID) public locale: string,
  ) {

    auth.account.subscribe((e) => {
      this.loggedIn = e;
      if (this.loggedIn) {
        const imageProfile = this.auth.getFieldJWT('Image');
        const languageProfile = this.auth.getFieldJWT('Language');
        if (!!imageProfile) {
          this.remote.getTokenFileUri(imageProfile).subscribe(res => this.image = res.url);
        }
        this.global.chageLanguage(languageProfile);
        const roles = this.auth.getRoles();

        if (canAccess(Role.BackOffice, roles)) {
          this.URLHome = '/backoffice-listado-cuentas';
          this.router.navigateByUrl('/backoffice-listado-cuentas');
        } else {
          this.auth.logout();
        }
      }
    });

    this.translate.use(locale);
  }

  logout() {
    this.auth.logout();
  }

  getPosition() {
    return mapPositionToTranslationKey(+this.auth.getFieldJWT('Position'));
  }
}
