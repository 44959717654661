import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { RemoteService } from '@app/services/remote.service';
import { CommonsService } from '@app/services/commons-service';
import { Toaster } from 'ngx-toast-notifications'
import { AuthenticationService } from '@app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [
  ]
})
export class ResetPasswordComponent implements OnInit {

  token: string;
  email: string;
  typePassword: boolean;
  typePasswordRepeat: boolean;
  isShow = false;
  isShowRepeat = false;

  private passwordUpdatedMessage: string;
  private passwordIncorrectMessage: string;
  private passwordUpdateFailure: string;

  model = {
    password: ['', 
        [
            Validators.required, 
            this.commons.passwordLengthValidator(),
            this.commons.passwordUpperValidator(),
            this.commons.passwordLowerValidator(),
            this.commons.passwordNumberValidator(),
            this.commons.passwordSpecialCharacterValidator()
        ]
    ],
    passwordConfirm: ['', Validators.required]
  }

  passwordsEqualValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('password');
    const passwordConfirm = control.get('passwordConfirm');
    
    return password.value !=  passwordConfirm.value ? { passwordsDifferent: true } : null;
  };

  formBuilder = new FormBuilder()
  resetPasswordForm = this.formBuilder.group(this.model, { validators: this.passwordsEqualValidator})


  constructor(
    private auth: AuthenticationService,
    private remote: RemoteService,
    private router: Router, 
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toast: Toaster,
    private commons: CommonsService,

  ) { 

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });

  }

  ngOnInit(): void {
    this.translate.get('ResetPassword.passwordUpdated')
      .subscribe(translation => {
        this.passwordUpdatedMessage = translation;
      });
    this.translate.get('ResetPassword.passwordIncorrect')
      .subscribe(translation => {
        this.passwordIncorrectMessage = translation;
      });
    this.translate.get('ResetPassword.passwordUpdateFailure')
      .subscribe(translation => {
        this.passwordUpdateFailure = translation;
      });
  }


  changePassword() {
    let payload = {
        token: this.token,
        email: this.email,
        password: this.resetPasswordForm.value.password
    }

    this.remote.postRequest('account/ResetPassword', payload)
        .then(r => {
            if (r.message == "OK") {
                this.toast.open({ text: this.passwordUpdatedMessage, duration: 6000 })
                this.router.navigateByUrl('login')
            }
            else
            {
                this.toast.open({ text: this.passwordIncorrectMessage, duration: 6000, type: 'danger' })
            }
        })
        .catch(r => {
            this.toast.open({ 
                text: this.passwordUpdateFailure, 
                duration: 6000, 
                type: 'danger' 
            })
        })        
  }

  get password() {
      return this.resetPasswordForm.get('password');
  }
    
  get passwordConfirm() {
      return this.resetPasswordForm.get('passwordConfirm');
  }

  //Mostrar password
  togglePassword = function () {
    this.typePassword = !this.typePassword; 
    this.isShow = !this.isShow;
 };
  //Mostrar repetir password
  togglePasswordRepeat = function () {
    this.typePasswordRepeat = !this.typePasswordRepeat; 
    this.isShowRepeat = !this.isShowRepeat;
 };

}
