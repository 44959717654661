import { Injectable , Input} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  @Input()
  rol: string;

  loggedIn = false;

  constructor(
    public auth: AuthenticationService,
    public router: Router) {

    auth.account.subscribe(e => {
      this.loggedIn = e;
    });
  }

  estaAutorizado(): boolean {
    const userRoles = this.auth.getRoles();
    if (this.rol) {
      return userRoles.filter(r => r === this.rol).length > 0;
    }
  }

  canActivate(): boolean {
    if (!this.loggedIn) {
        this.auth.logout();
        this.router.navigate(['/login']);
        return false;
    } else {
      return true;
    }
  }
}
