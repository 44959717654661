import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountProxyClient } from 'src/proxy/apiAccount/account.proxy';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private accountClient: AccountProxyClient) {}

  public updatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.accountClient.accountUpdatePasswordPost(oldPassword, newPassword);
  }
}
