import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { credentialsUser, ResponseAuthentication, TwoFactorDto } from '@app/models/RiskallayUser';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

    private authenticatedChanged: BehaviorSubject<boolean>;
    public account: Observable<boolean>;
    private readonly llaveToken = 'token';
    private readonly campoRol = 'role';
    private readonly currentUser = 'currentUser';
    private refreshTokenTimeout;

    constructor(
        private router: Router,
        private http: HttpClient,
        @Inject('API_URL') private serverUrl: string) {
        this.authenticatedChanged = new BehaviorSubject<boolean>(null);
        this.account = this.authenticatedChanged.asObservable();
    }

    public get accountValue(): boolean {
        return this.authenticatedChanged.value;
    }

    async authenticate(username: string, password: string) {
        let credentials: credentialsUser;
        credentials = {
            Email: username,
            password: password
        };
        try {
            const response = await this.http.post<ResponseAuthentication>(
                this.createCompleteRoute('Account/login', this.serverUrl), credentials, { withCredentials: true }).toPromise();

            if (response.Is2StepVerificationRequired) {
                return response;
            } else if (response.Token) {
                localStorage.setItem(this.llaveToken, response.Token);
                localStorage.setItem(this.currentUser, username);
                this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
                this.authenticatedChanged.next(true);
                return response;
            } else {
                return null;
            }
        } catch (e) {
            return e;
        }
    }

    getToken() {
        const token = localStorage.getItem(this.llaveToken);
        if (token) {
            return token;
        } else {
            this.authenticatedChanged.next(null);
            return null;
        }
    }

    getRequest(url) {
        const token = this.getToken();

        if (token) {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');

            const headerObj = {
                headers: headers
            };

            return this.http.get<any>(url, headerObj);
        } else {
            return null;
        }
    }

    logout() {
        this.http.post<any>(this.createCompleteRoute('Account/RevokeToken', this.serverUrl), {}, { withCredentials: true }).subscribe();
        this.stopRefreshTokenTimer();
        localStorage.removeItem(this.llaveToken);
        localStorage.removeItem(this.currentUser);
        this.authenticatedChanged.next(null);
        this.router.navigate(['/login']);
    }

    getRoles(): string[] {
        const token = localStorage.getItem(this.llaveToken);
        if (!token) { return []; }
        const dataToken = jwt_decode(token);
        return dataToken[this.campoRol];
    }

    getFieldJWT(field: string): string {
        const token = localStorage.getItem(this.llaveToken);
        if (!token) { return ''; }
        const dataToken = jwt_decode(token);
        return dataToken[field];
    }

    refreshToken() {        
        return this.http.post<ResponseAuthentication>(
            this.createCompleteRoute('Account/RefreshToken', this.serverUrl), null, { withCredentials: true })
            .pipe(map((response) => {
                if (response.Token) {
                    localStorage.setItem(this.llaveToken, response.Token);                    
                    this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
                    return 'OK';
                } else {
                    this.logout();
                }
            }));
    }

    async twoStepLogin(body: TwoFactorDto) {
        try {
            const response = await this.http.post<ResponseAuthentication>(
                this.createCompleteRoute('Account/TwoStepVerification', this.serverUrl), body, { withCredentials: true }).toPromise();
            if (response.Token) {
                localStorage.setItem(this.llaveToken, response.Token);
                localStorage.setItem(this.currentUser, body.Email);
                this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
                this.authenticatedChanged.next(true);
            }

            return response;

        } catch (error) {

        }
    }

    private createCompleteRoute = (route: string, envAddress: string) => {
        return `${envAddress}${route}`;
    }

    private startRefreshTokenTimer(expires: number) {                
        const timeout = new Date(expires*1000).getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
