<form class="c-form" #userForm="ngForm">
  <p id="infoCompany" class="c-fields__legend u-mt-1">
    {{'TablaBackofficeUsersForm.companyInfo.valor' | translate}}
  </p>
  <div class="row u-mt-1medium" aria-labelledby="infoCompany">
    <!--nombre-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="text" matInput name="company-name" value="" id="company-name" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.companyName.valor' | translate}}" [(ngModel)]="model.client.name"
          required />
      </mat-form-field>
    </div>
    <!--NIF-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="text" matInput name="company-cif" value="" id="company-cif" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.cif.valor' | translate}}" [(ngModel)]="model.client.companyCif"
          required />
      </mat-form-field>
    </div>
    <!--Pais-->
    <div class="col-lg-6 col-xl-4">
      <div class="c-select">
        <mat-form-field>
          <mat-label>{{'TablaBackofficeUsersForm.country.valor' | translate}}</mat-label>
          <mat-select name="country-id" id="country-id"
            placeholder="{{'TablaBackofficeUsersForm.country.valor' | translate}}" [(ngModel)]="model.client.countryId"
            required>
            <mat-option *ngFor="let country of countries$ | async" [value]="country.id">
              {{country.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <p id="account-status" class="c-fields__legend u-mt-2">
    Estado de la cuenta
  </p>
  <div class="row u-mt-1medium" aria-labelledby="company-active">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="active">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                          line-height: 24px;
                          margin-right:0.5rem;
                          margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="account-active"
              [(ngModel)]="model.client.active">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker1" name="contract-start" id="contract-start" class="c-form__input"
          placeholder="Contract start date" [ngModel]="model.client.contractStartDate"
          (ngModelChange)="model.client.contractStartDate = $event" required />
        <button mat-icon-button matSuffix (click)="picker1.open(); $event.stopPropagation()">
          <mat-icon>today</mat-icon>
        </button>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="false" [showSeconds]="false" [hideTime]="true">
          <ng-template>
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>
  <p id="account-status" class="c-fields__legend u-mt-2">
    Configuracion trial
  </p>
  <div class="row u-mt-1medium" aria-labelledby="company-active">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="active">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                          line-height: 24px;
                          margin-right:0.5rem;
                          margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="account-trial"
              [(ngModel)]="model.client.trial">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker" name="trial-end" id="trial-end" class="c-form__input"
          placeholder="Trial end date" [ngModel]="model.client.trialEnd"
          (ngModelChange)="model.client.trialEnd = $event" [required]="model.client.trial" />
        <button mat-icon-button matSuffix (click)="picker.open(); $event.stopPropagation()">
          <mat-icon>today</mat-icon>
        </button>
        <ngx-mat-datetime-picker #picker [showSpinners]="false" [showSeconds]="false" [hideTime]="true">
          <ng-template>
            <span>OK</span>
          </ng-template>
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>
  <p id="infoAdministrator" class="c-fields__legend u-mt-2">{{'TablaBackofficeUsersForm.adminInfo.valor' | translate}}
  </p>
  <div class="row u-mt-1medium" aria-labelledby="infoAdministrator">
    <!--nombre-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="text" matInput name="name" id="name" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.adminName.valor' | translate}}" [(ngModel)]="model.name" required />
      </mat-form-field>
    </div>
    <!--apellidos-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="text" matInput name="sur-name" value="" id="sur-name" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.adminSurName.valor' | translate}}" [(ngModel)]="model.surName" />
      </mat-form-field>
    </div>
    <!--email contacto-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="email" email matInput name="email" value="" id="email" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.adminEmail.valor' | translate}}" [(ngModel)]="model.email"
          required />
      </mat-form-field>
    </div>
    <!--teléfono-->
    <div class="col-lg-6 col-xl-3">
      <mat-form-field>
        <input type="tel" matInput name="phone-number" value="" id="phone-number" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.adminPhone.valor' | translate}}" [(ngModel)]="model.phoneNumber" />
      </mat-form-field>
    </div>
  </div>
  <p id="accountSettings" class="c-fields__legend u-mt-2">{{'TablaBackofficeUsersForm.settings.valor' | translate}}
  </p>
  <div class="row u-mt-1medium" aria-labelledby="infoAdministrator">
    <!--licencias de la cuenta-->
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="num-licenses" value="" min="0" step="1" id="num-licenses"
          class="c-form__input" placeholder="{{'TablaBackofficeUsersForm.licenses.valor' | translate}}"
          [(ngModel)]="model.client.numOfLicenses" required pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
    <!--limite de terceras partes de la cuenta-->
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="num-third-parties" value="" min="0" step="1" id="num-third-parties"
          class="c-form__input" placeholder="{{'TablaBackofficeUsersForm.numThirdParties.valor' | translate}}"
          [(ngModel)]="model.client.maxThirdParties" required pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
  </div>
  <p id="apiSettings" class="c-fields__legend u-mt-2">API Settings</p>
  <div class="row u-mt-1medium" aria-labelledby="api">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="api">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                line-height: 24px;
                                margin-right:0.5rem;
                                margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="api-enabled"
              [(ngModel)]="model.client.APIEnabled">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p id="refinitivSettings" class="c-fields__legend u-mt-2">Refinitiv</p>
  <div class="row u-mt-1medium" aria-labelledby="refinitiv">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="refinitiv">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                line-height: 24px;
                                margin-right:0.5rem;
                                margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="refinitiv-enabled"
              [(ngModel)]="model.isRefinitivEnabled">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="refinitiv-quota" min="0" step="1" id="refinitiv-quota" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.refinitivQuota' | translate}}"
          [(ngModel)]="model.client.refinitivQuota" required pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
  </div>
  <p id="gesintelSettings" class="c-fields__legend u-mt-2">Gesintel-AML Update</p>
  <div class="row u-mt-1medium" aria-labelledby="gesintel">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="gesintel">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                line-height: 24px;
                                margin-right:0.5rem;
                                margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="gesintel-enabled"
              [(ngModel)]="model.isAMLUpdateEnabled">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="gesintel-quota" min="0" step="1" id="gesintel-quota" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.amlUpdateQuota' | translate}}"
          [(ngModel)]="model.client.amlUpdateQuota" required pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
  </div>
  <p id="equifaxWSSettings" class="c-fields__legend u-mt-2">Equifax WS</p>
  <div class="row u-mt-1medium" aria-labelledby="equifaxWS">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="equifaxWS">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                line-height: 24px;
                                margin-right:0.5rem;
                                margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="equifaxWS-enabled"
              [(ngModel)]="model.isEquifaxWSEnabled">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="equifaxWS-quota" min="0" step="1" id="equifaxWS-quota" class="c-form__input"
          placeholder="{{'TablaBackofficeUsersForm.equifaxWS' | translate}}" [(ngModel)]="model.client.equifaxWSQuota"
          required pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
  </div>
  <p id="memberCheckSettings" class="c-fields__legend u-mt-2">Member check</p>
  <div class="row u-mt-1medium" aria-labelledby="memberCheck">
    <div class="col-lg-6 col-xl-4">
      <div class="c-switches c-switches--cols">
        <div class="c-switches--cols__group" aria-labelledby="memberCheck">
          <div class="c-switches__items">
            <mat-label class="mat-slide-toggle-content" style="vertical-align: top;
                                line-height: 24px;
                                margin-right:0.5rem;
                                margin-bottom:0.5rem;">{{'TablaBackofficeUsersForm.off' | translate}}</mat-label>
            <mat-slide-toggle class="c-switch" name="memberCheck-enabled"
              [(ngModel)]="model.isMemberCheckEnabled">{{'TablaBackofficeUsersForm.on' | translate}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-4">
      <mat-form-field>
        <input type="number" matInput name="memberCheck-quota" min="0" step="1" id="memberCheck-quota"
          class="c-form__input" placeholder="Member check quota" [(ngModel)]="model.client.memberCheckQuota" required
          pattern="^[0-9]*$" />
      </mat-form-field>
    </div>
  </div>
</form>
