import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Toaster } from 'ngx-toast-notifications';
import { RemoteService } from '@app/services/remote.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@app/services/configuration.service'
import { parsearErroresAPI } from '@app/utilities/utilities';
import { AuthenticationService } from '@app/services/authentication.service';
import { GlobalService } from '@app/services/global.service';
import { AwsS3Service } from '@app/services/awsS3.service';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { RiskallayUserUpdateDto } from 'src/proxy/model/riskallayUserUpdateDto';
import { forkJoin } from 'rxjs';
import { UsersService } from '@app/services/users.service';
import { Language, LanguageValues, mapLanguageToTranslationKey } from '@app/models/Language';
import { MatDialog } from '@angular/material/dialog';
import { DialogUpdatePasswordComponent } from './dialog-update-password/dialog-update-password.component';
import { mapPositionToTranslationKey, Position, PositionValues } from '@app/models/Position';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})

export class MyAccountComponent implements OnInit {

  form: FormGroup;
  errores: string[] = [];
  userdata: RiskallayUser;

  typePassword: boolean;
  typePasswordNew: boolean;
  isShow = false;
  isShowNew = false;

  uploadButtonText: string;
  selectedIconFileUri = null;
  selectedIconFileName = null;
  selectedIconFile = null;
  avatarUri: string;
  avatarUrl: string;
  languages: Language[] = [];

  dataUpdatedMessage: string;
  errorUpdatingMessage: string;

  positions: Position[];

  constructor(
    private fb: FormBuilder,
    private remoteService: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService,
    private configService: ConfigurationService,
    private usersService: UsersService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private awsS3Service: AwsS3Service,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.positions = PositionValues;
    this.languages = LanguageValues;

    this.form = this.fb.group({
      Name: new FormControl('', Validators.required),
      SurName: new FormControl('', Validators.required),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Position: new FormControl('', Validators.required),
      OldPassword: new FormControl(null, []),
      NewPassword: new FormControl(null, []),
      Foto: new FormControl(null, []),
      Language: new FormControl(null, Validators.required),
      TwoFactorEnabled: new FormControl(false, Validators.required)
    });

    this.loadInitialData();

    this.translate.onLangChange.subscribe(_ => {
      this.updateTranslatedValues();
    });

    this.updateTranslatedValues();
  }

  loadInitialData() {
    forkJoin({
      userData: this.usersService.getUserData()
    })
      .subscribe(({ userData }) => {
        this.getUserData(userData);

        this.form.get('Name').setValue(this.userdata.name);
        this.form.get('SurName').setValue(this.userdata.surName);
        this.form.get('Email').setValue(this.userdata.email);
        this.form.get('Position').setValue(this.userdata.position);
        this.form.get('Foto').setValue({ 'FileUri': this.selectedIconFileUri, 'FileName': this.selectedIconFileName, 'File': this.selectedIconFile });
        this.form.get('Language').setValue(this.userdata.language);
        this.form.get('TwoFactorEnabled').setValue(this.userdata.twoFactorEnabled);

        //Quitamos temporalmente el email y las contraseñas hasta definir proceso
        this.form.get('Email').disable();
        this.form.get('OldPassword').disable();
        this.form.get('NewPassword').disable();
      });
  }

  getUserData(r: RiskallayUser) {
    if (r !== null && r !== undefined) {
      this.userdata = r;

      if (this.userdata.profilePhotoFileUri !== null || this.userdata.profilePhotoFileName !== null) {
        this.selectedIconFileUri = this.userdata.profilePhotoFileUri;
        this.selectedIconFileName = this.userdata.profilePhotoFileName;
      } else {
        this.selectedIconFileUri = null;
        this.selectedIconFileName = null;
      }
    }
  }

  update() {
    let item = (<RiskallayUserUpdateDto>{});

    item.id = this.userdata.id.toString();
    item.name = this.form.get("Name").value;
    item.surName = this.form.get("SurName").value;
    item.email = this.form.get("Email").value;
    item.position = this.form.get("Position").value;
    item.oldPassword = this.form.get("OldPassword").value;
    item.newPassword = this.form.get("NewPassword").value;
    item.profilePhotoFileName = this.selectedIconFileName;
    item.profilePhotoFileUri = this.selectedIconFileUri;
    item.languageId = this.form.get("Language").value;
    item.twoFactorEnabled = this.form.get("TwoFactorEnabled").value;
    if (!!this.selectedIconFile) {
      this.configService.getFileStorageKey(item.profilePhotoFileName)
        .then(r => {
          item.profilePhotoFileUri = r.FileId;
          this.awsS3Service.uploadFileToS3(r.FileUri, this.selectedIconFile).subscribe();
        }).then(r => {
          this.updateAccountInfo(item);
        });
    } else {
      this.updateAccountInfo(item);
    }
  }

  private updateAccountInfo(item) {
    this.configService.updateAccountInfo(item)
      .subscribe(response => {
        this.authenticationService.refreshToken().subscribe(response => {
          this.globalService.chageLanguage(this.authenticationService.getFieldJWT('Language'));
        });

        this.loadInitialData();
        this.toaster.open({ text: this.dataUpdatedMessage, duration: 6000, type: 'info' });
      },
        errores => {
          var erroresList = parsearErroresAPI(errores);
          this.toaster.open({ text: erroresList.length > 0 ? erroresList[0] : this.errorUpdatingMessage, duration: 6000, type: 'danger' });
        });
  }

  loadAvatarImage() {
    if (this.selectedIconFileUri !== this.avatarUri) {
      this.remoteService.getTokenFileUri(this.selectedIconFileUri).subscribe(res => this.avatarUrl = res.url);
      this.avatarUri = this.selectedIconFileUri;
    }

    return this.avatarUrl;
  }

  changePassword(): void {
    this.dialog.open(DialogUpdatePasswordComponent, {});
  }

  selectDocument(resp) {
    this.selectedIconFile = resp.file;
    this.selectedIconFileName = resp.file.name;
  }

  private updateTranslatedValues() {
    this.translate.get('MiCuenta.foto.valor').subscribe((translation) => {
      this.uploadButtonText = translation;
    });

    this.translate.get('MiCuenta.dataUpdated')
      .subscribe(translation => {
        this.dataUpdatedMessage = translation;
      });

    this.translate.get('MiCuenta.errorUpdating')
      .subscribe(translation => {
        this.errorUpdatingMessage = translation;
      });
  }

  getPositionTranslationKey(position: Position) {
    if (position == undefined) return '';

    return mapPositionToTranslationKey(position);
  }

  getLanguageTranslationKey(language: Language) {
    if (language == undefined) {
      return '';
    }
    return mapLanguageToTranslationKey(language);
  }
}
