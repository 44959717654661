import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CountriesService } from '@app/services/countries.service';
import { Country } from '../../../models/Country';
import { RiskallayUser } from '../../../models/RiskallayUser';
import { NgForm } from '@angular/forms';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'dd/MM/yyyy'
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class AccountFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() model: RiskallayUser;
  @Output() formSubmitted = new EventEmitter<RiskallayUser>();
  @Output() validationChanged = new EventEmitter<boolean>();
  @ViewChild('userForm') public userForm: NgForm;
  private formStatusChangeSubscription: Subscription;

  countries$: Observable<Country[]>;
  successMessage: string;

  constructor(private categories: CountriesService) {}

  ngOnInit(): void {
    this.countries$ = this.categories.getCountries();
  }

  ngAfterViewInit(): void {
    this.formStatusChangeSubscription = this.userForm.statusChanges
      .subscribe(validationStatus => {
        let isValid = false;
        if(validationStatus as string === 'VALID') {
          isValid = true;
        }
        this.validationChanged.emit(isValid);
      });
  }

  ngOnDestroy(): void {
    this.formStatusChangeSubscription.unsubscribe();
  }

  submitForm(): void {
    this.formSubmitted.emit(this.model);
  }
}
