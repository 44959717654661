<div class="o-wrapper">
    <main id="content">
        <!--encabezado de sección-->
        <div class="c-head-section">
            <div class="c-head-section__left">
                <h2 class="o-title">{{'TablaBackofficeUsers.accounts.valor' | translate}}</h2>
            </div>
            <div class="c-head-section__right">
                <button type="button" (click)="openDialogAdd()" class="c-button c-button--ico light">
                    <span class="c-button__ico" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect class="a" width="24" height="24"/>
                            <path class="b" d="M14,8H8v6H6V8H0V6H6V0H8V6h6Z" transform="translate(5 5)"/>
                        </svg>
                    </span>
                    {{'TablaBackofficeUsers.new.valor' | translate}}
                </button>
            </div>
        </div>
        <!--contenido: buscador y tabla-->
        <div class="c-table u-mt-1medium">
            <app-backoffice-accounts-table></app-backoffice-accounts-table>
        </div>

    </main>
</div>