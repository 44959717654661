import { Injectable } from '@angular/core';
import { Usage } from '@app/models/Usage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsageProxyClient } from 'src/proxy/apiUsage/usage.proxy';
import { mapUsageDtoToUsage } from './mappings/usage.mappings';

@Injectable({
  providedIn: 'root',
})
export class UsageService {
  constructor(private usageClient: UsageProxyClient) {}

  public thirdPartyUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageThirdPartyUsageClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }

  public refinitivApiUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageRefinitivUsageClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }

  public amlUpdateApiUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageAmlUpdateUsageClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }

  public equifaxWSApiUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageEquifaxWSUsageClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }

  public memberCheckApiUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageMemberCheckUsageClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }

  public assessmentUsage(clientId: number): Observable<number> {
    return this.usageClient.usageCampaingnsCreatedClientIdGet(clientId);
  }

  public licenseUsage(clientId: number): Observable<Usage> {
    return this.usageClient.usageUsersCreatedClientIdGet(clientId)
      .pipe(
        map(u => mapUsageDtoToUsage(u))
      );
  }
}
