import { RiskallayUser } from '@app/models/RiskallayUser';
import { Role } from '@app/models/Role';
import { RiskallayClientUserCreateDto } from 'src/proxy/model/riskallayClientUserCreateDto';
import { RiskallayClientUserUpdateDto } from 'src/proxy/model/riskallayClientUserUpdateDto';
import { RiskallayUserDto } from 'src/proxy/model/riskallayUserDto';
import { mapClientDtoToClient } from './client.mappings';

export function mapUserDtoToUser(dto: RiskallayUserDto, currentLanguage: string): RiskallayUser {
  if (dto === null) {
    return null;
  }

  return <RiskallayUser>{
    id: dto.Id,
    name: dto.Name,
    surName: dto.SurName,
    email: dto.Email,
    phoneNumber: dto.PhoneNumber,
    roles: dto.Roles,
    client: mapClientDtoToClient(dto.Client, currentLanguage),
    position: dto.Position,
    profilePhotoFileName: dto.ProfilePhotoFileName,
    profilePhotoFileUri: dto.ProfilePhotoFileUri,
    language: dto.Language,
    isRefinitivEnabled: dto.Roles?.includes(Role.Refinitiv),
    twoFactorEnabled: dto.TwoFactorEnabled,
    isAMLUpdateEnabled: dto.Roles?.includes(Role.AMLUpdate),
    isEquifaxWSEnabled: dto.Roles?.includes(Role.EquifaxWS),
    isMemberCheckEnabled: dto.Roles?.includes(Role.MemberCheck),
  };
}

export function mapUserToUserCreateDto(
  user: RiskallayUser
): RiskallayClientUserCreateDto {
  return {
    companyName: user.client.name,
    companyCif: user.client.companyCif,
    numLicenses: user.client.numOfLicenses,
    email: user.email,
    name: user.name,
    surName: user.surName,
    phoneNumber: user.phoneNumber,
    countryId: user.client.countryId,
    maxThirdParties: user.client.maxThirdParties,
    isRefinitivEnabled: user.isRefinitivEnabled,
    refinitivQuota: user.client.refinitivQuota,
    isAMLUpdateEnabled: user.isAMLUpdateEnabled,
    amlUpdateQuota: user.client.amlUpdateQuota,
    equifaxWSQuota: user.client.equifaxWSQuota,
    active: user.client.active,
    trial: user.client.trial,
    trialEnd: user.client.trialEnd,
    contractStartDate: user.client.contractStartDate,
    isMemberCheckEnabled: user.isMemberCheckEnabled,
    memberCheckQuota: user.client.memberCheckQuota,
    enableAPI: user.client.APIEnabled
  };
}

export function mapUserToUserUpdateDto(
  user: RiskallayUser
): RiskallayClientUserUpdateDto {
  return {
    id: user.client.id,
    companyName: user.client.name,
    companyCif: user.client.companyCif,
    numLicenses: user.client.numOfLicenses,
    email: user.email,
    name: user.name,
    surName: user.surName,
    phoneNumber: user.phoneNumber,
    countryId: user.client.countryId,
    MaxThirdParties: user.client.maxThirdParties,
    isRefinitivEnabled: user.isRefinitivEnabled,
    refinitivQuota: user.client.refinitivQuota,
    isAMLUpdateEnabled: user.isAMLUpdateEnabled,
    amlUpdateQuota: user.client.amlUpdateQuota,
    isEquifaxWSEnabled: user.isEquifaxWSEnabled,
    equifaxWSQuota: user.client.equifaxWSQuota,
    active: user.client.active,
    trial: user.client.trial,
    trialEnd: user.client.trialEnd,
    contractStartDate: user.client.contractStartDate,
    isMemberCheckEnabled: user.isMemberCheckEnabled,
    memberCheckQuota: user.client.memberCheckQuota,
    enableAPI: user.client.APIEnabled
  };
}
