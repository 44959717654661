import { Component, OnInit, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { BackOfficeService } from '@app/services/back-office.service';
import { Client } from '@app/models/Client';
import { UsageService } from '@app/services/usage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-backoffice-accounts-table',
  templateUrl: './backoffice-accounts-table.component.html',
  styles: []
})
export class BackofficeAccountsTableComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("input", { static: true }) input: ElementRef;

  dataSource: any;
  displayedColumns: string[] = ['Company', 'Country', 'Cif', 'Licenses', "thirdParties", "assessments", 'Actions'];
  actions: string[] = ['Edit'];
  ready: boolean = false;
  clientData: Client[];

  constructor(
    private backOfficeService: BackOfficeService,
    public dialog: MatDialog,
    private usageService: UsageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadClientData();
  }

  loadClientData(): void {
    this.ready = false;
    this.backOfficeService
      .allClientData()
      .subscribe(clientData => {
        this.clientData = clientData;
        this.updateTable();
      });
  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.clientData);
    this.overrideFilter();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.ready = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  overrideFilter() {
    this.dataSource.filterPredicate = function (data: Client, filter: string): boolean {

      return String(data.id).toLowerCase().includes(filter.toLowerCase())
        || String(data.name).toLowerCase().includes(filter.toLowerCase())
        || String(data.country.description).toLowerCase().includes(filter.toLowerCase())
        || String(data.companyCif).toLowerCase().includes(filter.toLowerCase())
        || String(data.numOfLicenses).toLowerCase().includes(filter.toLowerCase())
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editAccount(element: Client) {
    this.router.navigate(['/view-account/', element.id]);
  }
}
