import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Country } from '@app/models/Country';
import { RiskallayUser } from '@app/models/RiskallayUser';
import { BackOfficeService } from '@app/services/back-office.service';
import { CountriesService } from '@app/services/countries.service';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { AccountFormComponent } from '../account-form/account-form.component';

@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss']
})
export class ViewAccountComponent implements OnInit {
  @ViewChild(AccountFormComponent) accountFormComponent: AccountFormComponent;
  public isFormValid: boolean;
  model: RiskallayUser;
  successMessage: string;
  countries$: Observable<Country[]>;


  constructor(
    private route: ActivatedRoute,
    private backOffice: BackOfficeService,
    private countriesService: CountriesService,
    private toaster: Toaster,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const clientId = +this.route.snapshot.paramMap.get('clientId');
    this.backOffice.clientDataById(clientId).subscribe(account => this.model = account);
    this.countries$ = this.countriesService.getCountries();

    this.translate
      .get('TablaBackofficeUsersForm.updateSuccessMessage.valor')
      .subscribe((translation) => {
        this.successMessage = translation;
      });
  }

  updateAccount(user: RiskallayUser) {
    this.backOffice.updateClient(this.model).subscribe(
      () => {
        this.toaster.open({
          text: this.successMessage,
          duration: 6000,
          type: 'info',
        });
      },
    );
  }

  checkFormValid(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  submitForm(): void {
    this.accountFormComponent.submitForm();
  }
}
