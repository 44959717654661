<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 id="titleDialog" class="c-dialog__title">{{'TablaBackofficeUsersForm.createTitle1.valor' | translate}}</h1>
        <button mat-button (click)="closeForm()" class="c-dialog__close"
            attr.aria-label="{{'TablaBackofficeUsersForm.closeModalWindow.valor' | translate}}">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content small-padding u-pb-0">
        <h2 class="c-dialog__subtitle">{{'TablaBackofficeUsersForm.createTitle2.valor' | translate}}</h2>
        <p class="o-text">{{'TablaBackofficeUsersForm.createInfo.valor' | translate}}</p>
        <app-account-form
            [model]="model"
            (formSubmitted)="addAccount($event)"
            (validationChanged)="checkFormValid($event)">
        </app-account-form>
    </div>
    <footer class="c-dialog__footer">
        <button [disabled]="!isFormValid" (click)="submitForm()" class="c-button c-button--green u-align-right"
            mat-button>{{'TablaBackofficeUsersForm.createButtonLabel.valor' | translate}}</button>
    </footer>
</div>
