import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  // Utility funtions for password validation
  public passwordLengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalidLength = control.value.length < 6
      return invalidLength ? { invalidLength: { value: control.value } } : null
    }
  }

  public passwordUpperValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainUpper = !(/[A-Z]/.test(control.value))
      return mustContainUpper ? { mustContainUpper: { value: control.value } } : null
    }
  }

  public passwordLowerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainLower = !(/[a-z]/.test(control.value))
      return mustContainLower ? { mustContainLower: { value: control.value } } : null
    }
  }

  public passwordNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainNumber = !(/[0123456789]/.test(control.value))
      return mustContainNumber ? { mustContainNumber: { value: control.value } } : null
    }
  }

  public passwordSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mustContainSpecial = !(/[~@#$%^&*+=`|{}:;!.?\"()\[\]-]/.test(control.value))
      return mustContainSpecial ? { mustContainSpecial: { value: control.value } } : null
    }
  }
}