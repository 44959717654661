<div *ngIf="!ready">
    <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
</div>

<mat-form-field class="c-search" floatLabel="never">
    <mat-label class="c-search__label" floatLabel="never">
        <span class="c-search__button" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M19.724,18.547,14.757,13.58a8.336,8.336,0,1,0-1.177,1.177l4.968,4.968a.832.832,0,1,0,1.177-1.177ZM8.317,14.975a6.658,6.658,0,1,1,6.658-6.658,6.658,6.658,0,0,1-6.658,6.658Z" transform="translate(.032.032)"/>
            </svg>
        </span> {{'TablaBackofficeUsers.search.valor' | translate}}
    </mat-label>
    <input matInput (keyup)="applyFilter($event)" class="c-search__input" #input [disabled]="(!dataSource)" />
</mat-form-field>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="Company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.company.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="Country" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.country.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element"> <span *ngIf="element.country">{{ element.country.description }}</span></td>
    </ng-container>

    <ng-container matColumnDef="Cif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.cif.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.companyCif}} </td>
    </ng-container>

    <ng-container matColumnDef="Licenses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.licenses.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element">{{element.numOfLicenses}} </td>
    </ng-container>

    <ng-container matColumnDef="thirdParties">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.thirdParties.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <app-third-party-usage [clientId]="element.id"></app-third-party-usage>
        </td>
    </ng-container>

    <ng-container matColumnDef="assessments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TablaBackofficeUsers.header.assessments.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <app-assessment-usage [clientId]="element.id"></app-assessment-usage>
        </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{'TablaBackofficeUsers.header.actions.valor' | translate}} </th>
        <td mat-cell *matCellDef="let element">

            <a [routerLink]="['/client-details',element.id]" class="c-table__watch">
                <span class="c-box-edit__button-ico" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13.333" viewBox="0 0 20 13.333">
                        <defs>
                            <style>.a{fill:#FDC130;}</style>
                        </defs>
                        <g transform="translate(-15.98 -96)">
                            <circle class="a" cx="2.667" cy="2.667" r="2.667" transform="translate(23.314 100)"/>
                            <path class="a" d="M35.766,101.942a14.122,14.122,0,0,0-4.136-4.189A10.467,10.467,0,0,0,25.967,96a9.982,9.982,0,0,0-5.178,1.5,15.178,15.178,0,0,0-4.57,4.42,1.33,1.33,0,0,0-.027,1.481,13.921,13.921,0,0,0,4.095,4.194,10.321,10.321,0,0,0,5.68,1.735,10.511,10.511,0,0,0,5.675-1.738,13.915,13.915,0,0,0,4.128-4.2A1.342,1.342,0,0,0,35.766,101.942Zm-9.785,4.725a4,4,0,1,1,4-4A4,4,0,0,1,25.981,106.667Z" transform="translate(0 0)"/>
                        </g>
                    </svg>
                </span> {{'TablaBackofficeUsers.actions.see.valor' | translate}}
            </a>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">{{'TablaBackofficeUsers.noresults.valor' | translate}} </td>
    </tr>
</table>

<mat-paginator [pageSize]="50" class="c-pagination">
</mat-paginator>
