import { Component, Input, OnInit } from "@angular/core";
import { UsageService } from "@app/services/usage.service";

@Component({
    selector: 'app-assessment-usage',
    templateUrl: './assessment-usage.component.html',
    styles: []
})
export class AssessmentUsageComponent implements OnInit {

    @Input() clientId: number;

    usage: string = '-';

    constructor(private usageService: UsageService) { }

    ngOnInit(): void {
        this.usageService.assessmentUsage(this.clientId).subscribe(r => this.usage = `${r}`);
    }    
}
