<div *ngIf="!loading" class="o-scroll o-scroll--small consumption-usage">
  <h1 class="o-title">{{'ConsumptionUsage.title' | translate}}</h1>
  <div class="row consumption-card-group">
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.licenseUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/users.svg" alt="Icono consumo licencias" />
        <div class="consumption-data">
          <p class="data">{{ licenseUsage.total - licenseUsage.remaining }} / {{ licenseUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.licenseUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.refinitivUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Refinitiv" />
        <div class="consumption-data">
          <p class="data">{{ refinitivUsage.total - refinitivUsage.remaining }} / {{ refinitivUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.refinitivUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.gesintelUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Gesintel-AML Update" />
        <div class="consumption-data">
          <p class="data">{{ amlUpdateUsage.total - amlUpdateUsage.remaining }} / {{ amlUpdateUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.gesintelUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.equifaxWSUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Equifax WS" />
        <div class="consumption-data">
          <p class="data">{{ equifaxWSUsage.total - equifaxWSUsage.remaining }} / {{ equifaxWSUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.equifaxWSUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.memberCheckUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/cloud-download.svg" alt="Icono consumo API Member check" />
        <div class="consumption-data">
          <p class="data">{{ memberCheckUsage.total - memberCheckUsage.remaining }} / {{ memberCheckUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.equifaxWSUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.thirdPartiesUsageTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/users.svg" alt="Icono terceros cargados" />
        <div class="consumption-data">
          <p class="data">{{ thirdPartyUsage.total - thirdPartyUsage.remaining }} / {{ thirdPartyUsage.total }}</p>
          <p class="data-label">{{'ConsumptionUsage.thirdPartiesUsageLabel' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="consumption-card">
      <h2 class="usage-title">{{'ConsumptionUsage.assessmentsLaunchedTitle' | translate}}</h2>
      <div class="consumption-body">
        <img src="assets/evaluaciones.svg" alt="Icono evaluaciones lanzadas" />
        <div class="consumption-data">
          <p class="data">{{ assessmentsLaunched }}</p>
          <p class="data-label">{{'ConsumptionUsage.assessmentsLaunchedLabel' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
