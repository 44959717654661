import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RemoteService } from '../../../services/remote.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '@app/models/Role';

@Component({
  selector: 'app-backoffice-login',
  templateUrl: './backoffice-login.component.html',
  styles: []
})
export class BackofficeLoginComponent implements OnInit {

  email: string;
  sent = false;
  typePassword: boolean;
  isShow = false;
  credentials: FormGroup = null;

  titulo: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private remote: RemoteService,
    private toaster: Toaster,
    private translate: TranslateService) {
    this.credentials = this.formBuilder.group({
      username: 'admin@dev.com',
      password: 'mjjaPAna1@',
    });
  }

  ngOnInit(): void {
    // Obtenermos altura de ventana y la multiplicamos por 1% para obtener valor para vh
    const vh = window.innerHeight * 0.01;
    // Establecemos valor de --vh a la raíz del documento
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // Evento redimensionar
    window.addEventListener('resize', () => {
        // Se ejecuta mismo script que antes
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

   // Make sure we are not logged in
   this.auth.logout();
  }

  sendMail() {
    const emailInfo = { email: this.email };
    this.remote.postRequest('account/sendLogin/', emailInfo)
        .then(r => this.sent = true)
        .catch(r => {
            this.translate.get('Login.log_error_send.valor').subscribe((text: string) => {
              this.toaster.open({ text: text, duration: 3000, type: 'danger'});
            });
        });
  }

  public async checkCredentials(e, credentials) {
    this.auth.authenticate(credentials.value.username, credentials.value.password)
        .then(r => {
            const loginResult = r;
            if (loginResult === 'OK') {
              const roles = this.auth.getRoles();
              if (roles.filter(rol => rol === Role.ThirdParty).length > 0 ) {
                this.router.navigateByUrl('/pagina-id');
              } else {
                this.router.navigateByUrl('/backoffice-listado-cuentas');
              }
            } else {
                this.translate.get('Login.log_error.valor').subscribe((text:string) => {
                  this.toaster.open({ text: text, duration: 6000, type: 'danger'});
                });
            }
        })
        .catch(r => {
          this.translate.get('Login.log_error_server.valor').subscribe((text: string) => {
            this.toaster.open({ text: text, duration: 6000, type: 'danger'});
          });
        });
  }

  // Mostrar password
  togglePassword = function () {
     this.typePassword = !this.typePassword;
     this.isShow = !this.isShow;
  };
}
