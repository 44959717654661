/**
 * Riskallay Project
 * Riskallay API Swagger surface
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ClientDto } from '../model/clientDto';
import { RiskallayClientUserCreateDto } from '../model/riskallayClientUserCreateDto';
import { RiskallayClientUserUpdateDto } from '../model/riskallayClientUserUpdateDto';
import { RiskallayUserDto } from '../model/riskallayUserDto';

import { Configuration }                                     from '../configuration';


@Injectable()
export class BackOfficeProxyClient {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject('API_URL') basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public backOfficeClientsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClientDto>>;
    public backOfficeClientsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClientDto>>>;
    public backOfficeClientsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClientDto>>>;
    public backOfficeClientsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClientDto>>('get',`${this.basePath}/BackOffice/Clients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public backOfficeCreateClientPost(body?: RiskallayClientUserCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public backOfficeCreateClientPost(body?: RiskallayClientUserCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public backOfficeCreateClientPost(body?: RiskallayClientUserCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public backOfficeCreateClientPost(body?: RiskallayClientUserCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/BackOffice/CreateClient`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idClient 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public backOfficeGetDataByClientIdIdClientGet(idClient: number, observe?: 'body', reportProgress?: boolean): Observable<RiskallayUserDto>;
    public backOfficeGetDataByClientIdIdClientGet(idClient: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RiskallayUserDto>>;
    public backOfficeGetDataByClientIdIdClientGet(idClient: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RiskallayUserDto>>;
    public backOfficeGetDataByClientIdIdClientGet(idClient: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idClient === null || idClient === undefined) {
            throw new Error('Required parameter idClient was null or undefined when calling backOfficeGetDataByClientIdIdClientGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RiskallayUserDto>('get',`${this.basePath}/BackOffice/GetDataByClientId/${encodeURIComponent(String(idClient))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public backOfficeReSendClientVerificationEmailPost(body?: RiskallayClientUserCreateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public backOfficeReSendClientVerificationEmailPost(body?: RiskallayClientUserCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public backOfficeReSendClientVerificationEmailPost(body?: RiskallayClientUserCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public backOfficeReSendClientVerificationEmailPost(body?: RiskallayClientUserCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/BackOffice/ReSendClientVerificationEmail`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idClient 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public backOfficeUpdateClientIdClientPut(idClient: number, body?: RiskallayClientUserUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public backOfficeUpdateClientIdClientPut(idClient: number, body?: RiskallayClientUserUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public backOfficeUpdateClientIdClientPut(idClient: number, body?: RiskallayClientUserUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public backOfficeUpdateClientIdClientPut(idClient: number, body?: RiskallayClientUserUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idClient === null || idClient === undefined) {
            throw new Error('Required parameter idClient was null or undefined when calling backOfficeUpdateClientIdClientPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/BackOffice/UpdateClient/${encodeURIComponent(String(idClient))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
