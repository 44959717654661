import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewAccountDialogComponent } from '../new-account-dialog/new-account-dialog.component.';
import { BackofficeAccountsTableComponent} from '../backoffice-accounts-table/backoffice-accounts-table.component'

@Component({
  selector: 'app-backoffice-accounts',
  templateUrl: './backoffice-accounts.component.html'
})
export class BackofficeAccountsComponent {

  @ViewChild(BackofficeAccountsTableComponent) table:BackofficeAccountsTableComponent;

  constructor(public dialog: MatDialog) { }

  // abrir modal
  openDialogAdd(): void {

    const dialogRef = this.dialog.open(NewAccountDialogComponent, {});

    dialogRef.afterClosed().subscribe(async result => {
      if (result===undefined){
        return
      }
      if (result) {
        this.table.loadClientData();
      }
    })
  }
}
