import { Country } from '@app/models/Country';
import { CountryDto } from 'src/proxy/model/countryDto';
import { mapDescriptionToCurrentLanguage } from './description-translation.mapping';

export function mapCountry(dto: CountryDto, currentLanguage: string): Country {
  if (dto === null) {
    return null;
  }

  return <Country>{
    id: dto.Id,
    code: dto.Code,
    codeAlpha3: dto.CodeAlpha3,
    description: mapDescriptionToCurrentLanguage(dto, currentLanguage),
  };
}
