export enum Role {
    ThirdParty = 'ThirdParty',
    Admin = 'Admin',
    Member = 'Member',
    BackOffice = 'BackOffice',
    Owner = 'Owner',
    Refinitiv = 'Refinitiv',
    AMLUpdate = 'AMLUpdate',
    EquifaxWS = 'EquifaxWS',
    MemberCheck = 'MemberCheck'
}

export interface RolDTO{
    Id: string;
    Name: string;
    NormalizedName: string;
    ConcurrencyStamp: string;
}
